import { queueDefinition } from './caseManagerConfig/queues/queueDefinitions';
import { menuStructure } from './caseManagerConfig/queues/menuStructure';
import { QueueItem } from './components/QueueItem';
import { taskLists } from './caseManagerConfig/taskLists';
import CaseSummary from './components/CaseSummary';
import { AddPledge } from './tasks/AddPledge/AddPledge';
import { ConfigureSignees } from './tasks/ConfigureSignees/ConfigureSignees';
import { StartPledgeProcess } from './tasks/StartPledgeProcess/StartPledgeProcess';
import { ApproveDocument } from './tasks/ApproveDocument/ApproveDocument';
import { StartTinglysing } from './tasks/StartTinglysing/StartTinglysing';
import { RejectedDocument } from './tasks/RejectedDocument/RejectedDocument';
import { StartSigning } from './tasks/StartSigning/StartSigning';
import { Archive } from './tasks/Archive/Archive';
import ArchiveTab from './components/ArchiveTab';
import { ExtendSigningDeadline } from './tasks/ExtendSigningDeadline/ExtendSigningDeadline';
import { AddMockedOrganizations } from './tasks/AddMockedOrganizations/AddMockedOrganizations';
import { SendReminderEmail } from './tasks/SendReminderEmail/SendReminderEmail';
import { User } from '../types/user';
import { RemoveOrganizations } from './tasks/RemoveOrganizations/RemoveOrganizations';

const customWidth = '70%';
const customHeight = '70%';

export const customComponents = (user: User) => ({
  case: {
    caseSummary: {
      component: CaseSummary,
    },
    businessStatus: {
      map: (flow) => {
        return flow.data.status;
      },
    },
    tasks: {
      taskLists: taskLists,
      taskComponents: {
        'add-pledge-information': {
          component: AddPledge,
          customWidth,
          customHeight,
        },
        'add-mocked-organizations': {
          component: AddMockedOrganizations,
          customWidth,
          customHeight,
        },
        'download-files-for-archiving': {
          component: Archive,
          customWidth: '80%',
          customHeight: '95%',
        },
        'configure-signees': {
          component: ConfigureSignees,
          titleMap: (task) =>
            task?.context?.organization?.name || 'configure-signees',
          customWidth,
          customHeight,
        },
        'start-pledge-sub-process': {
          component: StartPledgeProcess,
          customHeight,
          customWidth,
        },
        'document-control': {
          component: ApproveDocument,
          customWidth: '80%',
          customHeight: '95%',
        },
        'start-registration': {
          component: StartTinglysing,
          customWidth: '80%',
          customHeight: '95%',
        },
        'document-control-registration': {
          component: StartTinglysing,
          customWidth: '80%',
          customHeight: '95%',
        },
        'start-remove-organizations': {
          component: RemoveOrganizations,
          customWidth: '80%',
          customHeight: '95%',
        },
        'extend-deadline-message': {
          component: ExtendSigningDeadline,
          customWidth: '80%',
          customHeight: '95%',
        },
        'send-reminder-email-to-signee-manually': {
          component: SendReminderEmail,
          customWidth: '80%',
          customHeight: '95%',
        },
        'send-signing-tasks': {
          component: StartSigning,
          customHeight: '30%',
          customWidth: '40%',
        },
        'manual-registration': {
          component: RejectedDocument,
          customWidth: '80%',
          customHeight: '95%',
        },
      },
    },
    tabs: [
      {
        id: 'archive',
        title: 'archive-title',
        component: ArchiveTab,
      },
    ],
  },
  queue: {
    queueItem: QueueItem,
  },
  menu: {
    menuStructure: menuStructure,
  },
  queueDefinition: queueDefinition(user),
});

export const caseAssignmentConfiguration = {
  caseownersTeamId: (flow: any) => {
    return 'CASEWORKERS';
  },
};
